<template>
  <TitleCtaContainer :cta="ctaLink" :subtitle="subtitle" :title="title">
    <RecommendationCarousel
      :class="attrs.class"
      :recommendation-query="{
        widgetId: widgetId,
        limit: 16,
        personalisation: true,
      }"
      :tracking-data="trackingData"
      @error="onCarouselNoResults"
    />
  </TitleCtaContainer>
</template>

<script setup lang="ts">
import { computed, useAttrs } from 'vue'

import type { RecommendationsApiProps } from '@backmarket/http-api/src/api-specs-content/models/recommendations'
import RecommendationCarousel from '@backmarket/nuxt-layer-recommendation/RecommendationCarousel.vue'
import {
  type ExtraTrackingData,
  useListTrackingData,
} from '@backmarket/nuxt-layer-recommendation/useProductTracking'

import type { ContentBlockProps } from '@backmarket/nuxt-layer-cms/models/content-block'

import TitleCtaContainer from '../../shared-components/TitleCtaContainer/TitleCtaContainer.vue'

export type RecommendationsProps = RecommendationsApiProps

const attrs = useAttrs()

const props = defineProps<ContentBlockProps & RecommendationsProps>()
const listTrackingData = useListTrackingData({
  blockPosition: props.blockPosition,
})

const trackingData = computed<ExtraTrackingData>(() => ({
  productComponent: 'Recommendations',
  list: listTrackingData,
  ...props.tracking,
}))

// This event is common to every CMS block in order to hide the block containers
// on demand
const emit = defineEmits(['error'])

const onCarouselNoResults = () => {
  emit('error')
}
</script>
